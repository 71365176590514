<template>
  <main class="-mt-24 pb-8">
    <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 class="sr-only">About lending.tools</h1>
      <!-- Main 3 column grid -->
      <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
        <!-- Left column -->
        <div class="grid grid-cols-1 gap-4 lg:col-span-2">
          <section aria-labelledby="section-1-title">
            <div class="bg-white overflow-hidden shadow">

              <div class="p-6 prose max-w-none">
                <h2 id="section-1-title">Giving you information about lending cryptocurrencies</h2>

                <p>We are here to give you the latest insights and statistics on lending fiat, stablecoins and cryptocurrencies.</p>

                <p>At the front page, you will see an overview of the coins you are able to lend at on exchanges such as FTX.</p>

              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
