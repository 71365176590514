<template>
    <main class="-mt-24 pb-8">
        <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 class="sr-only">FTX Lending Rate {{ coin }}}</h1>
            <!-- Main 3 column grid -->
            <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
                <!-- Left column -->
                <div class="grid grid-cols-1 gap-4 lg:col-span-2">
                    <section aria-labelledby="section-1-title">
                        <div class="bg-white overflow-hidden shadow">

                            <div class="p-6 prose max-w-none">
                                <h2 id="section-1-title">Historical lending rates for {{ coin }} at FTX</h2>

                                <p>At the chart below you can check the lending rates for {{ coin }} for the previous 24 hours at FTX.com. Note that the lending rate is paid hourly and depends based on the hourly borrowing demand for {{ coin }}.</p>

                            </div>

                            <div class="flex px-6 justify-end">
                                <span class="relative z-0 inline-flex shadow-sm rounded-md">
                                  <button @click="getRates(24)" type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                    24 hours
                                  </button>
                                  <button @click="getRates(3*24)" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                    3 days
                                  </button>
                                  <button @click="getRates(7*24)" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                    7 days
                                  </button>
                                </span>
                            </div>

                            <vue-echarts :option="chart" style="width:100%; height: 500px" ref="chart" />

                        </div>
                    </section>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import orderBy from 'lodash/orderBy'

    //import LineChart from '../line-chart'
    import { VueEcharts } from 'vue3-echarts';

    export default {
        components: {
            VueEcharts
        },
        name: 'FTXRate',
        props: ['coin'],
        data() {
            return {
                orderBy: 'estimate',
                orderByDirection: 'desc',
                rates: [],
                currentCoin: null,
                chart: null
            }
        },
        methods: {
            getRates(hours = 24) {
                this.chart = {}
                fetch('https://lending.tools/api/rates/historical?hours='+hours+'&coin='+this.coin)
                    .then(response => response.json())
                    .then(data => {

                        let t = Object.entries(data).map((r) => {
                            return [new Date(parseInt(r[0])), r[1].previous*24*365*100, r[1].borrow_size]
                        })

                        console.log(t)

                        this.chart = {
                            title: {
                            },
                            dataset: {
                                source: t,
                                dimensions: ['timestamp', 'estimate', 'borrow_size']
                            },
                            tooltip: {
                                trigger: 'axis',
                                confine: true,
                                formatter: (params) => {
                                    params = params[0];
                                    return 'Date: '+params.value[0].toLocaleString()+'<br>'+'Coin: '+this.coin+'<br>APY : ' + params.value[1].toFixed(2)+'%<br>Borrow Size: '+params.value[2].toFixed(0);
                                },
                                axisPointer: {
                                    animation: false
                                }
                            },
                            xAxis: {
                                type: 'time',
                                splitLine: {
                                    show: false
                                }
                            },
                            yAxis: [{
                                type: 'value',
                                boundaryGap: [0, '100%'],
                                splitLine: {
                                    show: false
                                }
                            },{
                                type: 'value'
                            }],
                            series: [{
                                name: 'estimate',
                                type: 'line',
                                encode: {
                                    x: 'timestamp',
                                    y: 'estimate' // refer sensor 1 value
                                }
                            },{
                                name: 'borrow_size',
                                type: 'line',
                                yAxisIndex: 1,
                                encode: {
                                    x: 'timestamp',
                                    y: 'borrow_size'
                                }
                            }]
                        }
                    })
            }
        },
        computed: {
            sortedRates() {
                return orderBy([...this.rates], [this.orderBy], [this.orderByDirection])
            }
        },
        mounted() {
            this.getRates()
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
