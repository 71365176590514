<template>
    <div class="home">
        <FTXRate :coin="coin"/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import FTXRate from '@/components/FTXRate.vue'

    export default {
        name: 'Rate',
        props: ['coin'],
        components: {
            FTXRate
        }
    }
</script>
